<script setup lang="ts">
import { useMediaQuery } from '@vueuse/core'
import { MaIcon } from '@wirepay/maplerad-component-lib'
import Alert from '~/src/components/core/notification/Alert.vue'

const props = useAttrs() as {
  'on-close': Function
  title: string
  stages?: Record<string, { stepValue: number; stageValue: number }>
  steps?: { link: string | object; label: string }
  'wrapper-class': string
}
const route = useRoute()

const title = (props.title as string) || ''

const routeName = route.name as keyof typeof props.stages

const isMdScreen = useMediaQuery('(min-width: 768px)')

const activeRoute = ref(props?.stages?.[routeName] || { stepValue: 0, stageValue: 0 })

watch(
  () => route.name,
  (value) => {
    if (value)
      activeRoute.value = props?.stages?.[value as keyof typeof props.stages] || { stepValue: 0, stageValue: 0 }
  },
)
</script>

<template>
  <main class="bg-white relative">
    <TopBar>
      <template #title>
        <IconBtn
          label="close"
          class="flex-2"
          type="button"
          @click="props['on-close']"
        >
          <MaIcon
            name="add"
            class="w-6 !text-secondary rotate-45"
          />
        </IconBtn>
        <Typography
          as="h1"
          class="flex-1 hidden md:block"
          variant="xlBold"
        >
          {{ title }}
        </Typography>
        <Typography
          as="h1"
          class="flex-1 md:hidden"
          variant="xsMedium"
        >
          {{ title }}
        </Typography>
      </template>
    </TopBar>

    <div class="relative mt-24 md:mx-8 lg:mx-16 flex justify-center items-center">
      <nav
        v-if="props.steps"
        class="absolute left-0 top-0 right-0 md:right-auto md:bottom-0 bg-white z-[5] min-w-min px-5 md:px-0"
      >
        <Stepper
          :steps="props.steps"
          :active="activeRoute.stepValue"
          :direction="isMdScreen ? 'vertical' : 'horizontal'"
        />
      </nav>
      <LayoutContent
        :spacing="17"
        class="flex-1 pt-16 md:pt-0"
      >
        <div
          :class="props['wrapper-class']"
          class="max-w-xl md:max-w-2xl lg:max-w-[540px] md:pl-48 lg:pl-12 xl:pl-0 px-5 md:px-8 mx-auto"
        >
          <slot />
        </div>
      </LayoutContent>
    </div>
  </main>
  <Alert />
  <ModalBase />
</template>
